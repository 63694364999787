<template>
    <page back>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <el-row>
                <el-col :span="18">
                    <el-form
                        ref="addForm"
                        size="small"
                        :model="formModel"
                        :rules="formRules"
                    >
                        <el-form-item
                            label="店铺名称:"
                            label-width="10em"
                        >
                            {{ result.shopName }}
                        </el-form-item>
                        <el-form-item
                            label="门店名称:"
                            label-width="10em"
                            v-if="result.storeName"
                        >
                            {{ result.storeName }}
                        </el-form-item>
                        <el-form-item
                            label="买家姓名:"
                            label-width="10em"
                        >
                            {{ result.fullName }}
                        </el-form-item>
                        <el-form-item
                            label="反馈类型:"
                            label-width="10em"
                        >
                            {{ result.feedbackTypeName }}
                        </el-form-item>
                        <el-form-item
                            label="关联订单:"
                            label-width="10em"
                            v-if="result.orderSn"
                        >
                            <el-button
                                type="text"
                                @click="$router.push('/order/order/orderDetail/' + result.orderId)"
                            >
                                {{ result.orderSn }}
                            </el-button>
                        </el-form-item>
                        <el-form-item
                            label="正文:"
                            label-width="10em"
                        >
                            {{ result.content }}
                        </el-form-item>
                        <el-form-item
                            label="反馈图片:"
                            label-width="10em"
                            v-if="result.feedbackImage!=''"
                        >
                            <ImageList
                                :data="result.feedbackImageList"
                            />
                        </el-form-item>
                        <el-form-item
                            label="反馈时间:"
                            label-width="10em"
                        >
                            {{ result.feedbackTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="工单回复:"
                            label-width="10em"
                        >
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAdd"
                                class="ma-b"
                            >
                                新增回复
                            </el-button>
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="messageList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="sendTypeName"
                                    label="发送方"
                                    width="60"
                                />
                                <el-table-column
                                    prop="sendUserName"
                                    label="发送方用户"
                                    width="120"
                                />
                                <el-table-column
                                    prop="messageContent"
                                    label="内容"
                                    min-width="100"
                                />
                                <el-table-column
                                    label="创建时间"
                                    width="135"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>

                        <el-form-item
                            prop="handleRemarks"
                            label="处理备注"
                            label-width="10em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.handleRemarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="handleAttachment"
                            label="处理附件"
                            label-width="10em"
                        >
                            <upload
                                draggable
                                validate-md5
                                action="/rs/attachment/uploadFileLibrary"
                                tips=""
                                v-model="formModel.handleAttachment"
                            />
                        </el-form-item>
                    </el-form>
                    <div class="pa-a ta-c">
                        <el-button
                            size="small"
                            type="success"
                            @click="onSave"
                        >
                            结束工单
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </div>


        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="content"
                    label="内容"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.content"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
export default {
    name: 'WaitDealFeedbackEdit',
    data() {
        return {
            formModel: {
                id: '',
                handleRemarks: '',
                handleAttachment: [],
            },
            formRules: {
                handleRemarks: {
                    required: true,
                    message: '请输入处理备注',
                },
            },
            result: {},
            messageList: [],
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    content: '',
                    feedbackImage: [],
                },
                // 表单校验规则
                formRules: {
                    content: {
                        required: true,
                        message: '请输入内容',
                        trigger: 'blur',
                    },
                },
            },
        };
    },
    methods: {
        onSave() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.Feedback.dealFeedback({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        // 初始化数据
        init() {
            this.$api.Mb.Feedback.getDetail({
                id: this.formModel.id,
            }).then(json => {
                const res = json.data.data;
                this.result = res;
                this.messageList = res.messageList;
            });
        },
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.Feedback.submitMessage({
                    ...this.addDialog.formModel,
                    id: this.formModel.id,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
.redItem .el-form-item__label {
    color: red;
}
</style>
