<!-- Created by henian.xu on 2018/7/20. -->
<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="shopName"
                    label="店铺名称"
                >
                    <el-input
                        v-model="queryFormModel.shopName"
                        placeholder="请输入店铺名称"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeName"
                    label="门店名称"
                >
                    <el-input
                        v-model="queryFormModel.storeName"
                        placeholder="请输入门店名称"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="买家手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入买家手机号"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="feedbackType"
                    label="反馈类型"
                >
                    <dictionaries-picker
                        type="feedbackType"
                        v-model="queryFormModel.feedbackType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="shopName"
                    label="店铺名称"
                    min-width="100"
                />
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="买家手机号"
                    min-width="100"
                />
                <el-table-column
                    prop="feedbackTypeName"
                    label="反馈类型"
                    min-width="100"
                />
                <el-table-column
                    label="反馈时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.feedbackTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="feedbackStatusName"
                    label="处理状态"
                    width="80"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/customerServiceSheet/waitDealFeedback/edit/' + scope.row.id)"
                            v-if="scope.row.feedbackStatus == 10"
                        >
                            处理
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/customerServiceSheet/waitDealFeedback/view/' + scope.row.id)"
                            v-if="scope.row.feedbackStatus == 20 || scope.row.feedbackStatus == 100"
                        >
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'WaitDealFeedback',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                shopName: '', // 店铺名称
                storeName: '', // 门店名称
                mobile: '', // 卖家手机号
                feedbackType: '', // 反馈类型
                feedbackStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.Feedback.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
