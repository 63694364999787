<!-- Created by henian.xu on 2018/7/20. -->
<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>工单信息</span>
                    </div>
                    <el-col :span="15">
                        <div class="item">
                            <el-form-item
                                label="店铺名称:"
                                label-width="10em"
                            >
                                {{ result.shopName }}
                            </el-form-item>
                            <el-form-item
                                label="门店名称:"
                                label-width="10em"
                                v-if="result.storeName"
                            >
                                {{ result.storeName }}
                            </el-form-item>
                            <el-form-item
                                label="买家姓名:"
                                label-width="10em"
                            >
                                {{ result.fullName }}
                            </el-form-item>
                            <el-form-item
                                label="反馈类型:"
                                label-width="10em"
                            >
                                {{ result.feedbackTypeName }}
                            </el-form-item>
                            <el-form-item
                                label="正文:"
                                label-width="10em"
                            >
                                {{ result.content }}
                            </el-form-item>
                            <el-form-item
                                label="反馈图片:"
                                label-width="10em"
                                v-if="result.feedbackImage !=''"
                            >
                                <ImageList
                                    :data="result.feedbackImageList"
                                />
                            </el-form-item>
                            <el-form-item
                                label="反馈时间:"
                                label-width="10em"
                            >
                                {{ result.feedbackTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                            <el-form-item
                                label="工单回复:"
                                label-width="10em"
                            >
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAdd"
                                    class="ma-b"
                                >
                                    新增回复
                                </el-button>
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="messageList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="sendTypeName"
                                        label="发送方"
                                        width="60"
                                    />
                                    <el-table-column
                                        prop="sendUserName"
                                        label="发送方用户"
                                        width="120"
                                    />
                                    <el-table-column
                                        prop="messageContent"
                                        label="内容"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        label="创建时间"
                                        width="135"
                                    >
                                        <template slot-scope="scope">
                                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                          
                            <el-form-item
                                label="处理人姓名:"
                                label-width="10em"
                            >
                                {{ result.handleUserName }}
                            </el-form-item>
                            <el-form-item
                                label="处理人时间:"
                                label-width="10em"
                            >
                                {{ result.handleTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                            <el-form-item
                                label="处理备注:"
                                label-width="10em"
                            >
                                {{ result.handleRemarks }}
                            </el-form-item>
                            <el-form-item
                                label="处理附件:"
                                label-width="10em"
                            >
                                <ImageList
                                    :data="result.handleAttachmentList"
                                />
                            </el-form-item>
                        </div>
                        <el-form-item
                            label-width="10em"
                            class="ma-t"
                        >
                            <el-button
                                size="small"
                                @click="$router.back()"
                            >
                                返 回
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'WaitDealFeedbackView',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
            },
            result: {},
            messageList: [],
        };
    },
    methods: {
        init() {
            this.$api.Mb.Feedback.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.result = res;
                this.messageList = res.messageList;
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
